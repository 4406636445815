import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BackendURL, contractAddress } from "../utils/utils";
import ABI from "../utils/abi";
import { useStateContext } from "../context/AppContext";
import Web3 from "web3";
import { toast } from "react-toastify";
import WalletModal from "./WalletModal";

const Subscription = ({ ifTitle, ifCardOnStart, ifPaddingTop }) => {
  const cardRef = useRef(null);
  const navigate = useNavigate();

  const [title, setTitle] = useState([]);
  const [sectionData, setSectionData] = useState([]);
  const { userData, buttonBgColor, connectMetaMask } = useStateContext();

  useEffect(() => {
    Promise.all([
      axios.get(`${BackendURL}/subscriptiontitle`),
      axios.get(`${BackendURL}/createplans`),
    ])
      .then((res) => {
        setTitle(res[0].data);
        setSectionData(res[1].data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const cards = document.querySelectorAll(".pricings-card");
    let maxHeight = 0;
    cards.forEach((card) => {
      maxHeight = Math.max(maxHeight, card.offsetHeight);
    });
    cards.forEach((card) => {
      card.style.height = `${maxHeight}px`;
    });
  }, []);
  // console.log(sectionData, 'dats')
  const handlePricings = async (id, item) => {
    // console.log("item", item)
    // return
    if (userData) {
      axios
        .get(`${BackendURL}/getCashbackPercentage`)
        .then(async (res) => {
          console.log(res.data);
          const cashbackPercentage = res.data[0].cashback_howmuch;
          const rewardAmount = (cashbackPercentage / 100.0) * item.plan_amount_ether;
          console.log("cashbackPercentage", cashbackPercentage)
          console.log("rewardAmount", rewardAmount)
          // const rewardAmount = (cashbackPercentage / 100.0) * item.plan_amount;
          // const price = (parseFloat(item.plan_amount) / etherPrice)
          //   .toFixed(16)
          //   .toString();
          // const priceReward = (parseFloat(rewardAmount) / etherPrice)
          //   .toFixed(16)
          //   .toString();
          const web3 = new Web3(window.ethereum);
          await window.ethereum.enable();
          // Get the current account
          const accounts = await web3.eth.getAccounts();

          const account = accounts[0];
          let owner = account;
          let weiValue = 0;
          weiValue = Web3.utils.toWei(item.plan_amount_ether, "ether");
          const cashbackWeiValue = String(Math.round(rewardAmount * 10 ** 18));
          let contract = new web3.eth.Contract(ABI, contractAddress);
          
          contract.methods
            .subscribePlan(cashbackWeiValue)
            .send({
              from: owner,
              gas: 1500000,
              gasPrice: 0,
              value: weiValue,
            })
            .then(async function (result) {
              const currentDate = new Date();
              const expiryDate = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() + 1,
                currentDate.getDate()
              );

              const formData = {
                user_id: userData?.userId,
                purchased_plan_id: id,
                purchase_date: currentDate.toISOString(),
                expiry_date: expiryDate.toISOString(),
              };

              axios
                .post(`${BackendURL}/purchase/plans`, formData)
                .then((res) => {
                  const formDataCashback = {
                    cashback_list_price: rewardAmount,
                    user_id: userData?.userId,
                    puchase_plan_id: id,
                    puchase_plan_wallet_address: owner,
                    cashback_list_status: "Pending",
                  };

                  axios
                    .post(
                      `${BackendURL}/cashback_list/insert`,
                      formDataCashback
                    )
                    .then((res) => {
                      toast.success(
                        "Subscription Plan is purchased successfully!",
                        {
                          position: toast.POSITION.TOP_RIGHT,
                        }
                      );
                    })
                    .catch((error) => console.log(error));

                  // console.log(res.data);
                })
                .catch((error) => console.log(error));
            })
            .then(function (error) {
              console.log(error);
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    } else {
      connectMetaMask();
    }
  };

  /****
  const handleStripe = async (id, item) => {
    if (userData) {
      const stripe = onStripeShot();
      const currentDate = new Date();
      const expiryDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        currentDate.getDate()
      );

      axios
        .get(`${BackendURL}/getCashbackPercentage`)
        .then(async (res) => {
          const cashbackPercentage = res.data[0].cashback_howmuch;
          const rewardAmount = (cashbackPercentage / 100.0) * item.plan_amount;

          const formData = {
            user_id: userData.userId,
            purchased_plan_id: id,
            purchase_date: currentDate.toISOString(),
            expiry_date: expiryDate.toISOString(),
            plan_name: item.plan_name,
            plan_membership: item.plan_membership,
            plan_amount: item.plan_amount,
            cashback_list_price: rewardAmount,
            puchase_plan_id: id,
            puchase_plan_wallet_address: "",
            cashback_list_status: "Pending",
            purchase_plan_status: "Pending",
          };

          const checkOutSessionResponse = await axios.post(
            `${BackendURL}/create-checkout-session`,
            formData
          );
          console.log("checkOutSessionResponse", checkOutSessionResponse)
          return
          const redirect = await stripe.then((res) => {
            res.redirectToCheckout({
              sessionId: checkOutSessionResponse.data.id,
            });
          });

          if (redirect.error) return console.log(redirect.error.message);
        })
        .catch((error) => console.log(error));
    } else {
      connectMetaMask();
    }
  };
  ****/

  return (
    <>
      {sectionData?.length > 0 ? (
        <section
          className={`blog ${!ifPaddingTop ? "padding-top" : "mt-4"}`}
          // style={{ background: `url(${planbg})` }}
          id="subscriptionplans"
        >
          <div className="container">
            {!ifTitle ? (
              <div
                className="section-header text-center"
                // data-aos="fade-up"
                // data-aos-duration="1000"
              >
                <p className="subtitle">{title[0]?.subscription_tag}</p>
                <h2>{title[0]?.subscription_title}</h2>
              </div>
            ) : null}
            <div className="blog__wrapper">
              <div
                className={`row ${!ifCardOnStart ? "pricings-row" : "pricings-row-start"
                  }`}
              >
                {sectionData?.map((p, i) => (
                  <div
                    className="col-lg-6"
                    key={i}
                    data-aos="fade-up"
                    data-aos-duration="300"
                  >
                    <div
                      className="d-flex flex-column px-4 py-4 justify-content-between h-100 pricings-card round-theme"
                      ref={cardRef}
                      style={{ background: "#000" }}
                    >
                      <div className="d-flex align-items-start flex-column">
                        <span className="blog__meta-tag m-0">
                          {p.plan_membership}
                        </span>
                        <h4 className="text-white m-0">
                          <Link to={`#`}>{p.plan_name}</Link>
                        </h4>
                      </div>
                      <div className="p-0 mt-3 d-grid align-items-center gap-3">
                        <div dangerouslySetInnerHTML={{ __html: p.plan_description }}></div>
                      </div>
                      <div className="d-flex align-items-center gap-2 justify-content-center text-center">
                        <h3 className="m-0" style={{ color: "#0099ff" }}>
                          ${p.plan_amount}
                        </h3>
                        <sub className="fs-5">/month</sub>
                      </div>
                      <div className="d-flex align-items-center gap-2 justify-content-center text-center mb-3">
                        <p>
                          Allowed Vote: <span>{p.plan_totalvotes}</span>
                        </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-center border-top">
                        <button
                          type="button"
                          className="default-btn default-btn--small mt-3 btn w-100"
                          // onClick={() => handlePricings(p.plans_id, p)}
                          data-bs-toggle="modal"
                          data-bs-target={`#wallet-option-${i}`}
                          style={{
                            background: `${buttonBgColor[0]?.button_bgcolor}`,
                          }}
                        >
                          Purchase
                        </button>
                        <WalletModal
                          i={i}
                          p={p}
                          handlePricings={handlePricings}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};

export default Subscription;
