import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
import { Link as ReactRouterLink } from "react-router-dom";
import { whitelogo } from "../utils/assets";
import { useStateContext } from "../context/AppContext";
import { BackendURL } from "../utils/utils";
import ProfileObj from "./ProfileObj";
import { ColorRing } from "react-loader-spinner";
import axios from "axios";

const Navbar = () => {
  const { handleClick, sethandleClick, setscrollName,handleClick2, sethandleClick2 } = useStateContext();
  const {
    userData,
    setUserData,
    currentAccount,
    walletConnecting,
    connectMetaMask,
    buttonBgColor,
  } = useStateContext();
  const [openPopup, setOpenPopup] = useState(false);
  const [navBrand, setNavBrand] = useState([]);
  const [submenu, setSubmenu] = useState(false);
  const navigate = useNavigate();
  // const [handleClick, sethandleClick] = useState(false)

  const Location = window.location.pathname;

  const handlePopup = (prev) => setOpenPopup(!openPopup);

  const handleLogout = () => {
    localStorage.clear();
    setUserData(null);
    navigate("/");
  };

  useEffect(() => {
    axios
      .get(`${BackendURL}/antsbrand`)
      .then((res) => setNavBrand(res.data))
      .catch((error) => console.log(error));
  }, []);
  // console.log(navBrand, 'navbrand')


  const handleScroll = () => {
    var fixed_top = document.querySelector("header");
    if (window.pageYOffset > 30) {
      fixed_top.classList.add("header--fixed", "animated", "fadeInDown");
    } else {
      fixed_top.classList.remove("header--fixed", "animated", "fadeInDown");
    }
  };

  const handleMenuClick = () => {
    document.querySelector(".header-bar").classList.toggle("active");
    document.querySelector(".menu").classList.toggle("active");
  };

  const handleMenuItemClick = (e) => {
    if (window.innerWidth < 992) {
      //   console.log("Window width is less than 992 pixels");
      const menuItem = e.currentTarget;
      if (!menuItem.classList.contains("menu-item-has-children")) {
        document.querySelector(".header-bar").classList.remove("active");
        document.querySelector(".menu").classList.remove("active");
      }
    }
  };

  useEffect(() => {
    // Header
    window.addEventListener("scroll", handleScroll);

    document.querySelectorAll(".header-bar").forEach(function (element) {
      element.addEventListener("click", handleMenuClick);
    });

    document.querySelectorAll(".menu li").forEach(function (element) {
      element.addEventListener("click", handleMenuItemClick);
    });

    // Cleanup function to remove event listeners when the component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);

      document.querySelectorAll(".header-bar").forEach(function (element) {
        element.removeEventListener("click", handleMenuClick);
      });

      document.querySelectorAll(".menu li").forEach(function (element) {
        element.removeEventListener("click", handleMenuItemClick);
      });
    };
  }, []);

  const handleNavigate = (e) => {
    navigate('/')
    setscrollName(e)
  }


  return (
    <>
      {navBrand?.length > 0 ? <header className="header-section" onClick={() => sethandleClick(false)}>
        <div className="header-bottom">
          <div className="container">
            <div className="header-wrapper">
              <div className="logo">
                {Location === '/' ?
                  <Link
                    to="hero"
                    spy={true}
                    smooth={true}
                    offset={-120}
                    duration={500}
                    style={{ cursor: "pointer" }}
                    className="d-flex align-items-center gap-2"
                  ><img src={`${BackendURL}/nodeassets/${navBrand[0]?.antsbrand_img}`} width="250" alt="logo" /></Link>
                  :
                  <NavLink to={`/`}>
                    <img src={`${BackendURL}/nodeassets/${navBrand[0]?.antsbrand_img}`} width="250" alt="logo" />
                  </NavLink>
                }
              </div>
              <div className="menu-area">
                <ul className="menu d-flex align-items-center">
                  <li>
                    {Location === '/' ?
                      <Link
                        to="hero"
                        spy={true}
                        smooth={true}
                        offset={-120}
                        duration={500}
                        style={{ cursor: "pointer", fontSize: 14 }}
                        className="d-flex align-items-center gap-2"
                        onClick={(e)=>{
                          handleMenuClick()
                        }}
                      >Home</Link>
                      :
                      <NavLink style={{fontSize: 14}} to={`/`}>
                        Home
                      </NavLink>
                    }
                  </li>
                  <li>
                    <Link
                      style={{ cursor: "pointer", fontSize: 14 }}
                      className="d-flex align-items-center gap-2"
                      // onClick={() => {
                      //   setSubmenu(!submenu)
                      // }}
                      // onMouseEnter={() => setSubmenu(!submenu)}
                      onClick={() => sethandleClick(!handleClick)}
                      
                    >
                      About
                      <i className="fa fa-chevron-down text-white"></i>
                    </Link>

                    <ul className={`submenu ${handleClick && "submenuAbout"}`}>
                      <li>
                        {Location === '/' ?
                          <Link
                            to="aboutsection"
                            spy={true}
                            smooth={true}
                            offset={-120}
                            duration={500}
                            style={{ cursor: "pointer", fontSize: 13 }}
                            onClick={() => sethandleClick(false)}
                          >
                            About
                          </Link>
                          :
                          <Link
                            onClick={() => handleNavigate('aboutsection')}
                            style={{ cursor: "pointer", fontSize: 13 }}
                          >
                            About
                          </Link>
                        }
                      </li>
                      <li>
                        {Location === '/' ?
                          <Link
                            to="antswallet"
                            spy={true}
                            smooth={true}
                            offset={-100}
                            duration={500}
                            style={{ cursor: "pointer", fontSize: 13 }}
                            onClick={() => sethandleClick(false)}
                          >
                            Ants Wallet
                          </Link>
                          :
                          <Link
                            onClick={() => handleNavigate('antswallet')}
                            style={{ cursor: "pointer", fontSize: 13 }}
                          >
                            Ants Wallet
                          </Link>
                        }
                      </li>
                      <li>
                        {Location === '/' ?
                          <Link
                            to="antstoken"
                            spy={true}
                            smooth={true}
                            offset={-100}
                            duration={500}
                            style={{ cursor: "pointer", fontSize: 13 }}
                            onClick={() => sethandleClick(false)}
                          >
                            Ants Token
                          </Link>
                          :
                          <Link
                            onClick={() => handleNavigate('antstoken')}
                            style={{ cursor: "pointer", fontSize: 13 }}
                          >
                            Ants Token
                          </Link>
                        }
                      </li>
                      <li>
                        {Location === '/' ?
                          <Link
                            to="benefits"
                            spy={true}
                            smooth={true}
                            offset={-100}
                            duration={500}
                            style={{ cursor: "pointer", fontSize: 13 }}
                            onClick={() => sethandleClick(false)}
                          >
                            Benefits
                          </Link>
                          :
                          <Link
                            onClick={() => handleNavigate('benefits')}
                            style={{ cursor: "pointer", fontSize: 13 }}
                          >
                            Benefits
                          </Link>
                        }
                      </li>
                      <li>
                        {Location === '/' ?
                          <Link
                            to="antsdonations"
                            spy={true}
                            smooth={true}
                            offset={-100}
                            duration={500}
                            style={{ cursor: "pointer", fontSize: 13 }}
                            onClick={() => sethandleClick(false)}
                          >
                            Ants Donations
                          </Link>
                          :
                          <Link
                            onClick={() => handleNavigate('antsdonations')}
                            style={{ cursor: "pointer", fontSize: 13 }}
                          >
                            Ants Donations
                          </Link>
                        }
                      </li>
                    </ul>
                  </li>
                  <li>
                    {Location === '/' ?
                      <Link
                        to="roadmap"
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                        style={{ cursor: "pointer", fontSize: 14 }}
                        onClick={() => {
                          handleMenuClick()
                        }}
                      >
                        How it works
                      </Link>
                      :
                      <Link
                        onClick={() => handleNavigate('roadmap')}
                        style={{ cursor: "pointer", fontSize: 14 }}
                      >
                        How it works
                      </Link>
                    }
                  </li>
                  <li>
                    {Location === '/' ?
                      <Link
                        to="projects"
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                        style={{ cursor: "pointer", fontSize: 14 }}
                        onClick={() => {
                          handleMenuClick()
                        }}
                      >
                        Projects
                      </Link>
                      :
                      <Link
                        // onClick={()=>navigate('/',{state: { dataObj: 'projects' }})}
                        onClick={() => handleNavigate('projects')}
                        style={{ cursor: "pointer", fontSize: 14 }}
                      >
                        Projects
                      </Link>
                    }
                  </li>
                  <li>
                    {Location === '/' ?
                      <Link
                        to="subscriptionplans"
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                        style={{ cursor: "pointer", fontSize: 14 }}
                        onClick={() => {
                          handleMenuClick()
                        }}
                      >
                        Subscription
                      </Link>
                      :
                      <Link
                        onClick={() => handleNavigate('subscriptionplans')}
                        style={{ cursor: "pointer", fontSize: 14 }}
                      >
                        Subscription
                      </Link>
                    }
                  </li>
                  <li>
                    <Link
                      style={{ cursor: "pointer" }}
                      className="d-flex align-items-center gap-2"
                      onClick={() => sethandleClick2(!handleClick2)}
                    >
                      Knowledge
                      <i className="fa fa-chevron-down text-white"></i>
                    </Link>

                    <ul className={`submenu ${handleClick2 && "submenuAbout"}`}>
                      <li>
                        <ReactRouterLink
                          to='/knowledge'
                          spy={true}
                          smooth={true}
                          offset={-100}
                          duration={500}
                          style={{ cursor: "pointer", color: "#DDDDDD" }}
                        >
                          Knowledge
                        </ReactRouterLink>
                      </li>
                      <li>
                        <ReactRouterLink
                          to='/blogs'
                          spy={true}
                          smooth={true}
                          offset={-100}
                          duration={500}
                          style={{ cursor: "pointer", color: "#DDDDDD" }}
                        >
                          Blogs
                        </ReactRouterLink>
                      </li>
                    </ul>
                  </li>
                  <li className="d-flex d-lg-none mt-1 mb-3 px-4">
                    <button
                      className="btn text-black fw-bold py-2 flex align-items-center"
                      style={{
                        background: "#2caaf7",
                        fontSize: "0.9rem",
                        width: "100%",
                      }}
                      onClick={connectMetaMask}
                    >
                      <span className="text-white">Connect Wallet</span>
                      <i className="fa-solid fa-wallet text-white ms-2"></i>
                    </button>
                  </li>
                </ul>
                <div className="header-btn" onClick={handleMenuClick}>
                  <div className="header-bar d-lg-none">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  {userData ? (
                    <div
                      className="d-flex align-items-center user__nav__img"
                      style={{ position: "relative" }}
                      onClick={handlePopup}
                    >
                      <img
                        src={
                          userData?.profileImg
                            ? `${BackendURL}/nodeassets/${userData?.profileImg}`
                            : null
                        }
                        height={50}
                        width={50}
                        style={{
                          objectFit: "cover",
                          borderRadius: "100%",
                          border: "1px dashed #009dff",
                          padding: "0.05rem",
                          cursor: "pointer",
                        }}
                      />
                      {openPopup && <ProfileObj handleLogout={handleLogout} />}
                    </div>
                  ) : (
                    <div className="d-none d-lg-flex">
                      <button
                        type="button"
                        className="default-btn btn"
                        onClick={connectMetaMask}
                        style={{
                          lineHeight: "normal",
                          padding: "0.75rem 1.7rem",
                          background: `${buttonBgColor[0]?.button_bgcolor}`,
                        }}
                      // data-bs-toggle="modal"
                      // data-bs-target="#wallet-option"
                      >
                        <span>
                          Connect
                          {walletConnecting ? (
                            <ColorRing
                              visible={true}
                              height="21"
                              width="21"
                              ariaLabel="blocks-loading"
                              wrapperClass="blocks-wrapper"
                              colors={[
                                "#fff",
                                "#fff",
                                "#fff",
                                "#fff",
                                "#fff",
                                "#fff",
                              ]}
                            />
                          ) : (
                            <i className="fa-solid fa-wallet ms-2"></i>
                          )}
                        </span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header> : null}
    </>
  );
};

export default Navbar;
