import axios from "axios";
import React, { useEffect, useState } from "react";
import { BackendURL, contractAddress } from "../utils/utils";
import { truncate } from "lodash";
import Web3 from "web3";
import ABI from "../utils/abi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
const AntsToken = () => {
  const [data, setData] = useState([]);
  const [personalDonation, setpersonalDonation] = useState([])

  const [title, settitle] = useState('')
  const [description, setdescription] = useState('')
  const [etherPrice, setEtherPrice] = useState(0);

  const [donationAmount, setDonationAmount] = useState("");
  const [donationAmountEther, setDonationAmountEther] = useState("");
  useEffect(() => {
    axios
      .get(`${BackendURL}/antstoken`)
      .then((res) => setData(res?.data))
      .catch((err) => console.log(err));

    axios
      .get(`${BackendURL}/donationSection/get`)
      .then((res) => {
        settitle(res.data[0].title)
        setdescription(res.data[0].description)
      })
      .catch((err) => console.log(err));

    axios
      .get(`${BackendURL}/personalDonation/get`)
      .then((res) => setpersonalDonation(res?.data))
      .catch((err) => console.log(err));

    getDollarValues()
  }, []);
  function getDollarValues() {
    axios
      .get(
        `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin%2Cethereum&order=market_cap_desc  &per_page=100&page=1&sparkline=false`
      )
      .then((response) => {
        setEtherPrice(response.data[1].current_price);
      })
      .catch((error) => console.log(error));
  }
  // console.log(data, 'data')
  async function donateProject(walletAddress, projectPrice, projectPriceEther) {
    // console.log("walletAddress ", walletAddress)
    // console.log("projectPrice ", projectPrice)
    // return
    if(projectPriceEther == ""){
      toast.error("Enter Valid Amount!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return
    }
    if(parseFloat(projectPriceEther) <= 0){
      toast.error("Enter amount greater than zero!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return
    }
    // const price = (parseFloat(projectPrice) / etherPrice)
    //   .toFixed(16)
    //   .toString();
    const web3 = new Web3(window.ethereum);
    await window.ethereum.enable();
    // Get the current account
    const accounts = await web3.eth.getAccounts();
    const account = accounts[0];
    let owner = account;
    let weiValue = 0;
    weiValue = Web3.utils.toWei(projectPriceEther, "ether");
    let contract = new web3.eth.Contract(ABI, contractAddress);
    console.log(contract.methods);
    const antsOwner = "0xf60416159E455aac8F61649c1454B1d5D94c8676"
    
    contract.methods
      .donateProject(walletAddress, antsOwner)
      .send({
        from: owner,
        gas: 1500000,
        gasPrice: 0,
        value: weiValue
      })
      .then(async function (result) {
        console.log("Transaction:", result)
      })
      .then(function (error) {
        console.log(error);
      })
      .catch((error) => console.log(error));

  }
  return (
    <>
      {data?.length > 0 ? (
        <section className="about padding-top" id="antstoken">
          <div className="container">
            <div className="about__wrapper">
              <div className="row pricings-row">
                <div className="col-lg-6 align-items-center d-flex justify-content-center">
                  <div
                    className="about__thumb ants-token-img me-lg-5 me-0"
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    <img
                      src={`${BackendURL}/nodeassets/${data[0]?.wallet_image}`}
                      alt="tokenimg"
                      style={{ width: data[0]?.wallet_width, height: data[0]?.wallet_height }}
                    />
                  </div>
                </div>
                <div className="col-lg-6 d-flex align-items-start">
                  <div
                    className="about__content"
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    <div className="mint-step">
                      <p className="subtitle">Our Token</p>
                      <h3>
                        {data[0]?.wallet_title}
                      </h3>
                      <ul className="d-grid align-items-center">
                        <li
                          className="bg-transparent text-white px-0 py-0 list-group-item"
                          dangerouslySetInnerHTML={{
                            __html: data[0]?.wallet_text,
                          }}
                        ></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}
      <section className="about padding-top" id="antsdonations">
        <div className="container">
          <div className="about__wrapper">
            <div className="row pricings-row">

              <div>
                <h3 style={{ color: "#0099FF", textAlign: "center" }}>{truncate(title, { length: 31 })}</h3>
                <h6 className="pt-4 text-center text-trnsform-none">{description.replace(/<\/?p>/gi, "")}</h6>

                <div className="container pt-4">
                  {personalDonation?.map((t, i) => (
                    <div className="row pt-2">
                      <div className="col-md-9 col-12 ">
                        <p style={{ fontSize: "17px", paddingTop: "8px" }}>{t.pd_title}</p>
                      </div>
                      <div className="col-md-3 col-12 text-center">
                        <button
                          className="p-2 fw-bold"
                          data-bs-toggle="modal"
                          data-bs-target={`#donation-option-${i}`}
                          style={{ border: "1px solid #0099FF", backgroundColor: "transparent", color: '#0099FF', borderRadius: 30, width: '120px', fontSize: "18px" }}>
                          Donate
                        </button>
                        <div
                          className="wallet-modal modal fade"
                          id={`donation-option-${i}`}
                          tabIndex="-1"
                          aria-labelledby="choose-wallet"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title" id="choose-wallet">
                                  Donation Amount
                                </h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body py-0">
                                <ul className="wallet__list justify-content-center">
                                  <li className="wallet__list-item text-center justify-content-center align-items-center">
                                    <input
                                      placeholder="Enter Amount (USD)"
                                      type="number"
                                      value={donationAmount}
                                      onChange={(e)=>{
                                        setDonationAmount(e.target.value)
                                      }}
                                      className="form-control input-theme bg-black shadow-sm round-theme"
                                    />
                                    <input
                                      placeholder="Enter Amount (Ether)"
                                      type="number"
                                      value={donationAmountEther}
                                      onChange={(e)=>{
                                        setDonationAmountEther(e.target.value)
                                      }}
                                      className="form-control input-theme bg-black shadow-sm round-theme mt-1"
                                    />
                                    <Link
                                      href="#"
                                      style={{ border: "1px solid #0099FF", backgroundColor: "transparent", color: '#0099FF', borderRadius: 30, width: '120px', fontSize: "18px", float: 'right', marginTop:5 }}
                                      onClick={() => donateProject(t.pd_walletAddress, donationAmount, donationAmountEther)}
                                    >
                                      Donate
                                    </Link>
                                  </li>
                                </ul>
                                <p>
                                  By entering amount, you agree to our Terms of Service
                                  and our Privacy Policy.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AntsToken;
